import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout, { layoutContext } from "../components/layout"
import Seo from "../components/seo"



const BlankPage = () => {


  return (
    <Layout>
      <layoutContext.Consumer>
        {
          ({ setPopupStatus }) => (
            <>
              <Seo title="Privacy Policy" />

              <div className="container">
                <h2 className="text-h2 font-extrabold mt-16 mb-3">Privacy Policy</h2>
                <p className="leading-relaxed my-4">
                At Allied Transportation Group, we understand the importance of your privacy and the protection of your personal information. We are committed to safeguarding your information and have put in place appropriate measures to ensure that your personal data is secure and protected.
                </p>

                <h3 className="text-h3 font-semibold my-4">Information Collection and Use:</h3>

                <p className="leading-relaxed my-4">We collect personal information when you make a reservation for our services. This information includes your name, address, phone number, email address, and payment information. We use this information to provide you with the services you have requested, to communicate with you about your reservation, and to send you information about our company and services.

We may also collect non-personal information such as the type of browser you are using, your IP address, and the pages you visit on our website. This information is used to improve our website and to better understand our customers' needs and preferences.</p>


                <h3 className="text-h3 font-semibold my-4">Information Sharing:</h3>
                <p className="leading-relaxed my-4">We do not sell, rent, or share your personal information with third parties for their marketing purposes. We may share your information with our affiliates, service providers, and business partners who are involved in providing you with the services you have requested. We also may disclose your information to comply with legal requirements or to protect our rights, property, or safety.</p>
                <h3 className="text-h3 font-semibold my-4">Data Security:</h3>
                <p className="leading-relaxed my-4">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. We use industry-standard encryption technologies to protect your information during transmission and storage.</p>
                <h3 className="text-h3 font-semibold my-4">Your Rights:</h3>
                <p className="leading-relaxed my-4">You have the right to access, correct, or delete your personal information that we have collected. You also have the right to withdraw your consent to our processing of your personal information. If you wish to exercise these rights, please contact us using the contact information below.</p>
                <h3 className="text-h3 font-semibold my-4">Changes to the Privacy Policy:</h3>
                <p className="leading-relaxed my-4">We reserve the right to modify this privacy policy at any time. If we make material changes to this policy, we will notify you by email or by posting a notice on our website.</p>

              </div>




            </>
          )
        }
      </layoutContext.Consumer>





    </Layout>
  )
}


export default BlankPage
